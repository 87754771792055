import React, { useEffect, useState } from 'react';
import { Card, CardContent, CircularProgress, Divider, makeStyles, Typography } from '@material-ui/core';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    containerDistribution: {
        display: 'flex',
        width: '100%',
    },
    columnDistribution: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
    },
    lineDistribution: {
        width: '2px',
        backgroundColor: 'red',
        margin: 'auto',
    },
    divider: {
        margin: '6px 0',
    },
    title: {
        color: 'black',
        fontWeight: '600',
        fontFamily: 'Poppins',
        fontSize: '24px',
    },
    underlineTitle: {
        color: 'black',
        textDecoration: 'underline',
        fontWeight: '600',
        fontFamily: 'Poppins',
        fontSize: '16px',
    },
    mainInfo: {
        color: 'black',
        fontWeight: '600',
        fontFamily: 'Poppins',
        fontSize: '30px',
    },
    totalInfo: {
        color: 'black',
        fontWeight: '400',
        fontFamily: 'Poppins',
        fontSize: '16px',
        marginTop: '-10px',
    },
    historicalInfo: {
        color: 'black',
        fontWeight: '400',
        fontFamily: 'Poppins',
        fontSize: '16px',
        marginLeft: '5px',
    },
    titleDistribution: {
        color: 'black',
        fontWeight: '600',
        fontFamily: 'Poppins',
        fontSize: '30px',
        marginBottom: theme.spacing(1),
    },
    rowDistribution: {
        color: 'black',
        fontWeight: '400',
        fontFamily: 'Poppins',
        fontSize: '16px',
        marginBottom: theme.spacing(1),
    },
    progressBarBackground: {
        color: '#ddd',
    },
    progressBarAcceptance: {
        color: '#2FD3B5',
    },
    rowText: {
        color: 'black',
        fontWeight: '400',
        fontFamily: 'Poppins',
        fontSize: '12px',
    },
}));

function DashboardPage() {
    const classes = useStyles();

    const [data, setData] = useState([]);
    const [upperRow, setUpperRow] = useState([]);

    const fetchDashboardInfo = async () => {
        try {
            const isAuthenticated = JSON.parse(localStorage.getItem('userInfo'));
            const authToken = isAuthenticated ? isAuthenticated.token : '';

            const response = await fetch(`https://stormy-forest-22913.herokuapp.com/api/organization-dashboard-info/`, {
                headers: {
                    'Authorization': `Token ${authToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to Fetch Education Info');
            }
            const jsonData = await response.json();
            setData(jsonData || []);

            const newData = [
                {
                    line1: 'Users',
                    line2: jsonData.total_users.toString(),
                    line3: 'total',
                    lineWithIcon: `${jsonData.comparisons.users.change
                        } last month`,
                    icon: jsonData.comparisons.users.change >= 0 ? (
                        <ArrowUpward />
                    ) : (
                        <ArrowDownward />
                    ),
                },
                {
                    line1: 'Active Users',
                    line2: jsonData.active_users.toString(),
                    line3: 'past 30 days',
                    lineWithIcon: `${jsonData.comparisons.active_users.change
                        } last month`,
                    icon: jsonData.comparisons.active_users.change >= 0 ? (
                        <ArrowUpward />
                    ) : (
                        <ArrowDownward />
                    ),
                },
                {
                    line1: 'Return on Investment',
                    line2: `${jsonData.return_on_investment.toFixed(1)}x`,
                    line3: 'total',
                    lineWithIcon: `${jsonData.comparisons.return_on_investment.change.toFixed(1)
                        }x last month`,
                    icon: jsonData.comparisons.return_on_investment.change >= 0 ? (
                        <ArrowUpward />
                    ) : (
                        <ArrowDownward />
                    ),
                },
                {
                    line1: 'Money Saved',
                    line2: `$${jsonData.money_saved.toFixed(2)}`,
                    line3: 'total',
                    lineWithIcon: `$${jsonData.comparisons.money_saved.change.toFixed(2)
                        } last month`,
                    icon: jsonData.comparisons.money_saved.change >= 0 ? (
                        <ArrowUpward />
                    ) : (
                        <ArrowDownward />
                    ),
                },
            ];

            setUpperRow(newData);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchDashboardInfo();
    }, []);


    const getUserDistributionPercentage = (userMode) => {
        if (data.length === 0) return 0;
        if (data.user_distribution.length === 0) return 0;

        const userData = data.user_distribution.find((item) => item.user_mode === userMode);
        const totalUsers = userData ? userData.total : 0;
        const totalPercentage = (totalUsers / getTotalUsers()) * 100;
        return totalPercentage.toFixed(2) + '%';
    };

    const getUserDistributionTotal = (userMode) => {
        if (data.length === 0) return 0;

        const userData = data.user_distribution.find((item) => item.user_mode === userMode) * 3;
        return userData ? userData.total : 0;
    };

    const getTotalUsers = () => {
        if (data.length === 0) return 0;

        return data.user_distribution.reduce((total, item) => total + item.total, 0);
    };

    if (data.length === 0) {
        return <div style={{ marginTop: 70 }}>Loading...</div>;
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });
    }

    return (
        <div style={{ marginTop: 70 }}>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {upperRow.map((item, index) => (
                    <Card key={index} style={{ height: 150, minWidth: 200, margin: '6px', borderRadius: '20px' }}>
                        <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                            <Typography className={classes.underlineTitle}>{item.line1}</Typography>
                            <Typography className={classes.mainInfo}>{item.line2}</Typography>
                            <Typography className={classes.totalInfo}>{item.line3}</Typography>
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                {item.icon}
                                <Typography className={classes.historicalInfo}>{item.lineWithIcon}</Typography>
                            </div>
                        </CardContent>
                    </Card>
                ))}
                <Card style={{ height: 320, minWidth: 400, margin: '6px', borderRadius: '20px' }}>
                    <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                        <Typography className={classes.underlineTitle}>
                            Users Distribution (%)
                        </Typography>
                        <div className={classes.containerDistribution}>
                            <div className={classes.columnDistribution}>
                                <div className={classes.titleDistribution}>
                                    {getUserDistributionPercentage('angel')}
                                </div>
                                <div className={classes.rowDistribution}>Angel</div>
                            </div>
                            <div className={classes.lineDistribution}></div>
                            <div className={classes.columnDistribution}>
                                <div className={classes.titleDistribution}>
                                    {getUserDistributionPercentage('both')}
                                </div>
                                <div className={classes.rowDistribution}>Both</div>
                            </div>
                            <div className={classes.lineDistribution}></div>
                            <div className={classes.columnDistribution}>
                                <div className={classes.titleDistribution}>
                                    {getUserDistributionPercentage('user')}
                                </div>
                                <div className={classes.rowDistribution}>User</div>
                            </div>
                        </div>
                        <Typography className={classes.underlineTitle}>
                            Users Distribution
                        </Typography>
                        <div className={classes.containerDistribution}>
                            <div className={classes.columnDistribution}>
                                <div className={classes.titleDistribution}>
                                    {getUserDistributionTotal('angel')}
                                </div>                                <div className={classes.rowDistribution}>Angel</div>
                            </div>
                            <div className={classes.lineDistribution}></div>
                            <div className={classes.columnDistribution}>
                                <div className={classes.titleDistribution}>
                                    {getUserDistributionTotal('both')}
                                </div>
                                <div className={classes.rowDistribution}>Both</div>
                            </div>
                            <div className={classes.lineDistribution}></div>
                            <div className={classes.columnDistribution}>
                                <div className={classes.titleDistribution}>
                                    {getUserDistributionTotal('user')}
                                </div>
                                <div className={classes.rowDistribution}>User</div>
                            </div>
                        </div>
                    </CardContent>
                </Card>
                <Card style={{ height: 320, minWidth: 250, margin: '6px', borderRadius: '20px' }}>
                    <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                        <Typography className={classes.underlineTitle}>
                            Acceptance Rate
                        </Typography>
                        <Typography className={classes.mainInfo}>
                            {(data.acceptance_rate * 100).toFixed(2)}%
                        </Typography>
                        <Typography className={classes.totalInfo}>
                            total
                        </Typography>
                        <div style={{ marginLeft: 23.5, width: 170, height: 170 }}>
                            <CircularProgress
                                variant="determinate"
                                value={100}
                                className={classes.progressBarBackground}
                                style={{ position: 'absolute', width: 170, height: 170 }}
                            />
                            <CircularProgress
                                variant="determinate"
                                value={data.acceptance_rate * 100}
                                className={classes.progressBarAcceptance}
                                style={{ position: 'absolute', width: 170, height: 170 }}
                            />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                            <ArrowUpward />
                            <Typography className={classes.historicalInfo}>
                                {(data.comparisons.acceptance_rate.change * 100).toFixed(2)}% last month
                            </Typography>
                        </div>
                    </CardContent>
                </Card>
                <Card style={{ height: 320, minWidth: 350, margin: '6px', borderRadius: '20px' }}>
                    <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                        <Typography className={classes.underlineTitle} style={{ marginBottom: '10px' }}>
                            Education Centre
                        </Typography>
                        {data.most_viewed_articles.length > 0 ? (
                            data.most_viewed_articles.map((row, index) => (
                                <React.Fragment key={index}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography className={classes.rowText}>
                                            {row.article_title}
                                        </Typography>
                                        <Typography className={classes.rowText}>
                                            {row.views} views
                                        </Typography>
                                    </div>
                                    {index !== data.most_viewed_articles.length - 1 && <Divider className={classes.divider} />}
                                </React.Fragment>
                            ))
                        ) : (
                            <Typography className={classes.rowText}>No Info Yet</Typography>
                        )}
                    </CardContent>
                </Card>
                <Card style={{ height: 320, minWidth: 350, margin: '6px', borderRadius: '20px' }}>
                    <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                        <Typography className={classes.underlineTitle} style={{ marginBottom: '10px' }}>
                            Incident Centre
                        </Typography>
                        {data.most_recent_incidents.length > 0 ? (
                            data.most_recent_incidents.map((row, index) => (
                                <React.Fragment key={index}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography className={classes.rowText}>
                                            {row.title} - {row.subject.toUpperCase()}
                                        </Typography>
                                        <Typography className={classes.rowText}>
                                            {formatDate(row.date_created)}
                                        </Typography>
                                    </div>
                                    {index !== data.most_recent_incidents.length - 1 && <Divider className={classes.divider} />}
                                </React.Fragment>
                            ))
                        ) : (
                            <Typography className={classes.rowText}>
                                No Info Yet
                            </Typography>
                        )}
                    </CardContent>
                </Card>
            </div>
        </div>
    );
}

export default DashboardPage;
