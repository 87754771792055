import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    loginWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: "url('https://webappvisuals.s3.ca-central-1.amazonaws.com/TreeCollection.png')",
        backgroundSize: 'cover',
        backgroundPosition: 'center bottom',
        height: '95vh',
        width: '100vw',
    },
    loginBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '2em',
        border: '2px solid black',
        borderRadius: '25px',
        boxShadow: '5px 5px 5px grey',
        backgroundColor: '#fff',
    },
    companyLogo: {
        width: '200px',
        height: '90px',
    },
    heading: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 600,
        fontSize: '20px',
    },
    errorText: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 400,
        fontSize: '12px',
        color: '#F53361',
        marginBottom: '1em',
        textAlign: 'center',
    },
    styledButton: {
        borderRadius: '10px',
        backgroundColor: '#2FD3B5',
        color: 'white',
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 600,
        fontSize: '16px',
        textTransform: 'none',
        marginTop: '20px',
        '&:hover': {
            backgroundColor: '#29bda5',
        },
        '&:active': {
            backgroundColor: '#279e89',
        },
    },
    emailText: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 400,
        fontSize: '12px',
        color: 'black',
        marginBottom: '1em',
        textAlign: 'center',
        textDecoration: 'underline',
    },
}));

function LoginForm() {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                password: password,
            })
        };

        fetch('https://stormy-forest-22913.herokuapp.com/api/organization/login/', requestOptions)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Login Failed');
                }
            })
            .then(data => {
                localStorage.setItem('userInfo', JSON.stringify(data));
                const isAuthenticated = JSON.parse(localStorage.getItem('userInfo'));
                const authToken = isAuthenticated ? isAuthenticated.token : '';

                fetch('https://stormy-forest-22913.herokuapp.com/api/organization-info/', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${authToken}`,
                    },

                })
                    .then(response => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            throw new Error('Login Failed');
                        }
                    })
                    .then(data => {
                        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
                        const userOrgState = userInfo.org_state;
                        console.log("User ORG State: ", userOrgState);
                        const stateInfo = data.find(item => item.org_state === userOrgState);
                        localStorage.setItem('stateInfo', JSON.stringify(stateInfo));
                        console.log("StateInfo: ", stateInfo);

                        navigate('/dashboard');
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        if (error.on_fields_errors === "You Are Not Authorized to Log In.") {
                            setError("You Are Not Authorized to Log In.");
                        } else {
                            setError("If you forgot your Email or Password, please contact support@havensafe.co");
                        }
                    });
            })
            .catch(error => {
                console.error('Error:', error);
                if (error.on_fields_errors === "You Are Not Authorized to Log In.") {
                    setError("You Are Not Authorized to Log In.");
                } else {
                    setError("If you forgot your Email or Password, please contact support@havensafe.co");
                }
            });
    };

    return (
        <div className={classes.loginWrapper}>
            <div className={classes.loginBox}>
                <img className={classes.companyLogo} src="https://webappvisuals.s3.ca-central-1.amazonaws.com/Official+Haven+Logo+Green.png" alt="Company Logo" />
                <h1 className={classes.heading}>Welcome to Haven</h1>
                <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '350px' }}>
                    <TextField
                        label="Email"
                        variant="outlined"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Password"
                        type="password"
                        variant="outlined"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    {error && <p className={classes.errorText}>{error}</p>}
                    <Button
                        variant="contained"
                        type="submit"
                        className={classes.styledButton}
                        disableElevation
                    >
                        Login
                    </Button>
                    <p className={classes.emailText}>
                        <a href="mailto:support@havensafe.co?subject=Forgot%20Email%20or%20Password&body=Hi%20Team%20Haven%2C%0D%0AI%20forgot%20my%20Email%20or%20Password...">
                            Forgot Email/Password
                        </a>
                    </p>
                </form>
            </div>
        </div>
    );
}

export default LoginForm;
