import React from 'react';
import { makeStyles } from '@material-ui/core/';

const useStyles = makeStyles((theme) => ({
  companyImage: {
    width: '30%',
  },
  image: {
    marginTop: 50,
    width: '100%',
  },
  text: {
    fontSize: '16px',
    marginBottom: theme.spacing(1),
  },
  title: {
    fontSize: '24px',
    marginBottom: theme.spacing(1),
  },
  unsupportedMobilePage: {
    padding: theme.spacing(2),
  },
}));

const UnsupportedMobilePage = () => {
  const classes = useStyles();

  return (
    <div className={classes.unsupportedMobilePage}>
      <img
        className={classes.companyImage}
        src="https://webappvisuals.s3.ca-central-1.amazonaws.com/Official+Haven+Logo+Green.png" alt="Company Logo"
      />
      <h1 className={classes.title}>To Use Haven, Use a Desktop or Laptop!</h1>
      <p className={classes.text}>
        We're sorry, but the Haven Safety Portal is only supported on Desktop and Laptop devices.
      </p>
      <p className={classes.text}>
        Please switch to a Desktop or Laptop to access the full functionality of Haven.
      </p>
      
      <img
        className={classes.image}
        src="https://webappvisuals.s3.ca-central-1.amazonaws.com/TreeCollection.png" alt="Company Logo"
      />
      
    </div>
  );
};

export default UnsupportedMobilePage;