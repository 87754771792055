import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    title: {
        color: 'black',
        fontWeight: '600',
        fontFamily: 'Poppins',
        fontSize: '24px',
    },
    subTitle: {
        color: 'black',
        fontWeight: '600',
        fontFamily: 'Poppins',
        fontSize: '24px',
    },
    normalText: {
        color: 'black',
        fontWeight: '400',
        fontFamily: 'Poppins',
        fontSize: '12px',
    },
    dragDropField: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 'calc(100vw - 320px)',
        height: '120px',
        border: '2px solid black',
        borderRadius: '10px',
        backgroundColor: 'white',
        cursor: 'pointer',
        marginTop: '8px',
    },
    dragOver: {
        backgroundColor: 'lightgray',
    },
    uploadIcon: {
        fontSize: '40px',
        marginRight: '10px',
    },
    fileInput: {
        display: 'none',
    },
    seatCount: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '5px',
    },
    userInviteStatus: {
        display: 'flex',
        flexDirection: 'column',
        width: 'calc(100vw - 340px)',
        border: '2px solid black',
        borderRadius: '10px',
        backgroundColor: 'white',
        cursor: 'pointer',
        marginTop: '8px',
        padding: '10px',
        marginBottom: '10px',
    },
    divider: {
        margin: '6px 0',
    },
    rowText: {
        color: 'black',
        fontWeight: '400',
        fontFamily: 'Poppins',
        fontSize: '12px',
        textAlign: 'left',
    },
    acceptedText: {
        color: '#2FD3B5',
        fontWeight: '600',
        fontFamily: 'Poppins',
        fontSize: '12px',
        textAlign: 'left',
    },
    rowTextTitle: {
        color: 'black',
        fontWeight: '600',
        textDecoration: 'underline',
        fontFamily: 'Poppins',
        fontSize: '16px',
        textAlign: 'left',
    },
}));


function IncidentPage() {
    const classes = useStyles();
    const stateInfo = JSON.parse(localStorage.getItem('stateInfo'));
    const [incidentReports, setIncidentReports] = useState([]);
    const [userInfo, setUserInfo] = useState(null);

    const fetchIncidentReports = async () => {
        const isAuthenticated = JSON.parse(localStorage.getItem('userInfo'));
        const authToken = isAuthenticated ? isAuthenticated.token : '';

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`,
            },
        };

        fetch('https://stormy-forest-22913.herokuapp.com/api/organization-incidents/', requestOptions)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to Retrieve Reports');
                }
            })
            .then(data => {
                setIncidentReports(data);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const fetchUserProfile = async (userProfile) => {
        const isAuthenticated = JSON.parse(localStorage.getItem('userInfo'));
        const authToken = isAuthenticated ? isAuthenticated.token : '';

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`,
            },
        };

        try {
            const response = await fetch(`https://stormy-forest-22913.herokuapp.com/api/organization-view-user-profile/?id=${userProfile}`, requestOptions);
            if (response.ok) {
                const data = await response.json();
                console.log(data);
                setUserInfo(prevUserInfo => ({
                    ...prevUserInfo,
                    [userProfile]: data,
                }));
            } else {
                throw new Error('Failed to retrieve user profile');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });
    }

    useEffect(() => {
        fetchIncidentReports();
    }, []);

    useEffect(() => {
        if (incidentReports.length > 0) {
            incidentReports.forEach(report => {
                if (!report.anonymous) {
                    fetchUserProfile(report.user_profile);
                }
            });
        }
    }, [incidentReports]);

    return (
        <div style={{ marginTop: 70 }}>
            <Typography className={classes.subTitle}>
                Incidents
            </Typography>
            <Typography className={classes.normalText}>
                View all Incidents reported by {stateInfo.org_id.org_name} users here.
            </Typography>
            {incidentReports.length > 0 && (
                incidentReports.map(report => (
                    <div key={report.report_id} className={classes.userInviteStatus}>
                        <Typography className={classes.rowTextTitle}>
                            {report.title} - {report.subject.toUpperCase()}
                        </Typography>
                        <Typography className={classes.rowText}>
                            {report.content}
                        </Typography>
                        <Divider className={classes.divider} />
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography className={classes.normalText}>
                                {formatDate(report.date_created)}
                            </Typography>
                            {!report.anonymous && userInfo && userInfo[report.user_profile] ? (
                                <Typography className={classes.normalText}>
                                    {userInfo[report.user_profile].first_name} {userInfo[report.user_profile].last_name} | {userInfo[report.user_profile].email}
                                </Typography>
                            ) : (
                                <Typography className={classes.normalText}>
                                    Anonymous
                                </Typography>
                            )}
                        </div>
                    </div>
                ))
            )}
        </div>
    );
}

export default IncidentPage;
