import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, TextField, Typography, makeStyles, useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    loginWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'radial-gradient(circle, #2FD3B5 20%, #A8FFD8 40%, #FFFFFF 60%, #A8FFD8 80%, #2FD3B5 100%)',
        backgroundSize: '300% 300%',
        animation: '$gradientFlow 45s infinite linear',
        height: '100vh',
        width: '100vw',
    },
    '@keyframes gradientFlow': {
        '0%, 100%': { backgroundPosition: '100% 0%' },
        '50%': { backgroundPosition: '0% 100%' },
    },
    loginBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '2em',
        border: '2px solid black',
        borderRadius: '25px',
        boxShadow: '5px 5px 5px grey',
        backgroundColor: '#fff',
        width: 500,
        '@media (max-width:768px)': {
            width: 300,
        },
    },
    companyLogo: {
        width: '200px',
        height: '90px',
    },
    heading: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 600,
        fontSize: '20px',
    },
    errorText: props => ({
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 400,
        fontSize: '12px',
        color: props.success ? '#2FD3B5' : '#F53361',
        marginBottom: '1em',
        textAlign: 'center',
    }),
    styledButton: {
        borderRadius: '10px',
        backgroundColor: '#2FD3B5',
        color: 'white',
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 600,
        fontSize: '16px',
        textTransform: 'none',
        marginTop: '20px',
        marginBottom: '20px',
        '&:hover': {
            backgroundColor: '#29bda5',
        },
        '&:active': {
            backgroundColor: '#279e89',
        },
    },
    emailText: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 400,
        fontSize: '12px',
        color: 'black',
        marginBottom: '1em',
        textAlign: 'center',
        textDecoration: 'underline',
    },
    normalText: {
        color: 'black',
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: '400',
    },
}));

function LoginForm() {
    const isMobile = useMediaQuery('(max-width:600px)');
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [requestStatus, setRequestStatus] = useState(null);
    const location = useLocation();

    const isSuccessful = requestStatus === 200;  
    const classes = useStyles({ isMobile, success: isSuccessful });

    const deriveEmailDomainAndOrgState = () => {
        switch (location.pathname) {
            case "/university-of-toronto":
                return { subtitle: "Provided by the UTSU", email_domain: "mail.utoronto.ca", org_state: "UTSU2023" };
            case "/university-of-windsor":
                return { subtitle: "Provided by the UWSA", email_domain: "uwindsor.ca", org_state: "UWSA2023" };
            case "/university-of-ottawa":
                return { subtitle: "Provided by SEUO-UOSU", email_domain: "uottawa.ca", org_state: "UOSU2023" };
            case "/nipissing-university":
                return { subtitle: "Provided by NUSU", email_domain: "my.nipissingu.ca", org_state: "NUSU2023" };
            default:
                return { subtitle: "", email_domain: "", org_state: "" };
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        const { subtitle, email_domain, org_state } = deriveEmailDomainAndOrgState();

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                email: email,
                school: email_domain,
                org_state: org_state,
            })
        };

        fetch('https://stormy-forest-22913.herokuapp.com/api/organization-premium-invite-individual/', requestOptions)
            .then(response => {
                setRequestStatus(response.status);  
                return response.json().then(data => {
                    if (!response.ok) {
                        const errorMsg = data.error || 'Error From Server.'; 
                        throw new Error(errorMsg);
                    }
                    return data;  
                });
            })
            .then(data => {
                setError(data.message);
            })
            .catch(error => {
                console.error('Error:', error);
                setError(error.message);
            });
    };
    document.body.style.overflow = 'hidden'; 
    const { subtitle, email_domain, org_state } = deriveEmailDomainAndOrgState();
    return (
        <div className={classes.loginWrapper}>
            <div className={classes.loginBox}>
                <img className={classes.companyLogo} src="https://webappvisuals.s3.ca-central-1.amazonaws.com/Official+Haven+Logo+Green.png" alt="Company Logo" />
                <Typography className={classes.heading}>Welcome to Haven</Typography>
                <Typography className={classes.normalText}>{subtitle}</Typography>
                <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <TextField
                        label="Email"
                        variant="outlined"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        style={{ width: 300 }}
                        margin="normal"
                    />
                    {error && <p className={classes.errorText}>{error}</p>}
                    <Button
                        variant="contained"
                        type="submit"
                        className={classes.styledButton}
                        disableElevation
                    >
                        Get Code
                    </Button>
                </form>
            </div>
        </div>
    );
}

export default LoginForm;
