import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { makeStyles, Typography } from '@material-ui/core';
import './App.css';
import './styles.css';
import UniversityIndividual from './Pages/UniversityIndividual';
import LoginPage from './Pages/LoginPage';
import Sidebar from './Sidebar/Sidebar';
import Navbar from './Sidebar/Navbar';
import DashboardPage from './Pages/DashboardPage';
import PremiumInvitePage from './Pages/PremiumInvitePage';
import NotificationPage from './Pages/NotificationPage';
import EducationPage from './Pages/EducationPage';
import EducationDetailPage from './Pages/EducationDetailPage';
import ArticleDetailPage from './Pages/ArticleDetailPage';
import IncidentPage from './Pages/IncidentPage';
import MapPage from './Pages/MapPage';
import UnsupportedMobilePage from './Pages/UnsupportedMobilePage';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    overflowX: 'hidden',
  },
  content: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'auto',
  },
  maincontent: {
    paddingLeft: '300px',
  },
  navbar: {
    position: 'fixed',
    width: '100%',
  },
  navbarHigherZIndex: {
    zIndex: 10,
  },
  navbarBackground: {
    position: 'fixed',
    top: '64px',
    left: '0',
    width: '100%',
    height: '60px',
    backgroundColor: 'white',
    borderTop: '1px solid black',
    borderBottom: '1px solid black',
    zIndex: '2',
  },
  header: {
    marginLeft: '300px',
    marginTop: '14px',
    color: 'black',
    fontWeight: '600',
    fontFamily: 'Poppins',
    fontSize: '24px',
  },
}));

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const isAuthenticated = JSON.parse(localStorage.getItem('userInfo'));

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [navigate, isAuthenticated]);

  return isAuthenticated ? children : <Navigate to="/login" />;
};

const App = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    document.body.style.overflowX = 'hidden';

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      document.body.style.overflowX = 'auto';
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isSpecialRoute = (path) => {
    const specialRoutes = ['/university-of-toronto', '/nipissing-university', '/university-of-windsor', '/university-of-ottawa'];
    return specialRoutes.includes(path);
  };

  return (
    <Router>
      {isMobile && !isSpecialRoute(window.location.pathname) ? <UnsupportedMobilePage /> : <Layout />}
    </Router>
  );
};

const Layout = () => {
  const classes = useStyles();
  const location = useLocation();

  const isHideNavbarAndSidebarRoute = (path) => {
    const paths = [
        '/login',
        '/university-of-toronto',
        '/nipissing-university',
        '/university-of-windsor',
        '/university-of-ottawa'
    ];
    return paths.includes(path);
  };

  const navbarClass = isHideNavbarAndSidebarRoute(location.pathname) ? '' : `${classes.navbar} ${classes.navbarHigherZIndex}`;
  const mainContentClass = isHideNavbarAndSidebarRoute(location.pathname) ? '' : classes.maincontent;
  const paddingTopStyle = isHideNavbarAndSidebarRoute(location.pathname) ? '0' : '64px';

  let contentText;
  if (location.pathname === '/dashboard') {
    contentText = 'Dashboard';
  } else if (location.pathname === '/premium') {
    contentText = 'Haven Premium';
  } else if (location.pathname === '/notifications') {
    contentText = 'Push Notifications';
  } else if (location.pathname === '/notifications') {
    contentText = 'Notifications';
  } else if (location.pathname === '/education') {
    contentText = 'Education Centre';
  } else if (location.pathname === '/incidents') {
    contentText = 'Incident Centre';
  } else if (location.pathname === '/map') {
    contentText = 'Map';
  } else {
    contentText = 'Other Content';
  }

  return (
    <div className={classes.root} style={{ overflowX: 'hidden' }}>
      {!isHideNavbarAndSidebarRoute(location.pathname) && <div className={navbarClass}><Navbar /></div>}
      {!isHideNavbarAndSidebarRoute(location.pathname) && 
        <div className={classes.navbarBackground}>
          <Typography className={classes.header}>
            {contentText}
          </Typography>
        </div>
      }
      <div className={classes.content} style={{ paddingTop: paddingTopStyle }}>
        {!isHideNavbarAndSidebarRoute(location.pathname) && <Sidebar />}
        <div className={mainContentClass}>
          <Main />
        </div>
      </div>
    </div>
  )
}

const Main = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" replace />} />
      <Route path="/university-of-toronto" element={<UniversityIndividual />} />
      <Route path="/nipissing-university" element={<UniversityIndividual />} />
      <Route path="/university-of-windsor" element={<UniversityIndividual />} />
      <Route path="/university-of-ottawa" element={<UniversityIndividual />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/dashboard" element={
        <ProtectedRoute>
          <DashboardPage />
        </ProtectedRoute>
      } />
      <Route path="/premium" element={
        <ProtectedRoute>
          <PremiumInvitePage />
        </ProtectedRoute>
      } />
      <Route path="/notifications" element={
        <ProtectedRoute>
          <NotificationPage />
        </ProtectedRoute>
      } />
      <Route path="/education" element={
        <ProtectedRoute>
          <EducationPage />
        </ProtectedRoute>
      } />
      <Route path="/education/:titleId" element={
        <ProtectedRoute>
          <EducationDetailPage />
        </ProtectedRoute>
      } />
      <Route path="/education/:titleId/:articleId" element={
        <ProtectedRoute>
          <ArticleDetailPage />
        </ProtectedRoute>
      } />
      <Route path="/incidents" element={
        <ProtectedRoute>
          <IncidentPage />
        </ProtectedRoute>
      } />
      <Route path="/map" element={
        <ProtectedRoute>
          <MapPage />
        </ProtectedRoute>
      } />
    </Routes>
  )
};

export default App;
