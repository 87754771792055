import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AccountCircle, Dashboard, Notifications, School, LocationOn, Report } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  sidebar: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    width: '250px',
    backgroundColor: 'white',
    height: '100%',
    borderRight: '1px solid grey',
    borderTop: '1px solid grey',
    padding: '1rem',
    marginTop: '5px',
    borderRadius: '5px',
    zIndex: '3',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  image: {
    width: '50px',
    height: '50px',
    marginRight: '10px',
  },
  name: {
    fontWeight: '600',
    fontFamily: 'Poppins',
    lineHeight: '1',
  },
  text: {
    fontFamily: 'Poppins',
    fontSize: '12px',
  },
  divider: {
    margin: '1rem 0',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    height: '40px',
    fontWeight: 'semibold',
    fontFamily: 'Poppins',
    fontSize: '16px',
    textTransform: 'none',
    marginBottom: '0.5rem',
    borderRadius: '10px',
    border: '1.5px solid black',
  },
  activeButton: {
    backgroundColor: '#2FD3B5',
    color: '#fff',
    border: '0px solid black',
  },
  icon: {
    height: '30px',
    width: '30px',
    marginLeft: theme.spacing(1),
  },
}));

const Sidebar = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const stateInfo = JSON.parse(localStorage.getItem('stateInfo'));
  const isActive = (path) => {
    return location.pathname === path;
  };

  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'long' };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  }


  return (
    <div className={classes.sidebar}>
      <div className={classes.header}>
        <img className={classes.image} src={stateInfo.org_id.org_logo_url} alt="Profile" />
        <div>
          <Typography variant="subtitle1" className={classes.name}>
            {stateInfo.org_id.org_name}
          </Typography>
          <Typography variant="body2" className={classes.text}>
            {formatDate(stateInfo.org_start_date)} - Present
          </Typography>
        </div>
      </div>
      <Divider className={classes.divider} />
      <Button
        onClick={() => navigate('/dashboard')}
        className={isActive('/dashboard') ? classes.activeButton : ''}
        classes={{ root: classes.button }}
        startIcon={<Dashboard className={classes.icon} />}
      >
        Dashboard
      </Button>
      <Button
        onClick={() => navigate('/premium')}
        className={isActive('/premium') ? classes.activeButton : ''}
        classes={{ root: classes.button }}
        startIcon={<AccountCircle className={classes.icon} />}
      >
        Invite Users
      </Button>
      {stateInfo.org_push_notification_enabled && (

        <Button
          onClick={() => navigate('/notifications')}
          className={isActive('/notifications') ? classes.activeButton : ''}
          classes={{ root: classes.button }}
          startIcon={<Notifications className={classes.icon} />}
        >
          Push Notifications
        </Button>
      )}
      {stateInfo.org_education_centre_enabled && (

        <Button
          onClick={() => navigate('/education')}
          className={isActive('/education') ? classes.activeButton : ''}
          classes={{ root: classes.button }}
          startIcon={<School className={classes.icon} />}
        >
          Education Centre
        </Button>
      )}
      {stateInfo.org_reporting_centre_enabled && (

        <Button
          onClick={() => navigate('/incidents')}
          className={isActive('/incidents') ? classes.activeButton : ''}
          classes={{ root: classes.button }}
          startIcon={<Report className={classes.icon} />}
        >
          Incidents Centre
        </Button>)}
      {stateInfo.org_map_enabled && (

        <Button
          onClick={() => navigate('/map')}
          className={isActive('/map') ? classes.activeButton : ''}
          classes={{ root: classes.button }}
          startIcon={<LocationOn className={classes.icon} />}
        >
          Map
        </Button>
      )}
    </div>
  );
};

export default Sidebar;
