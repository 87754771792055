import React, { useEffect, useRef, useState } from 'react';
import { Divider, makeStyles, Typography } from '@material-ui/core';
import { CloudUploadOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    acceptedText: {
        color: '#2FD3B5',
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: '600',
        textAlign: 'left',
    },
    divider: {
        margin: '6px 0',
    },
    dragDropField: {
        alignItems: 'center',
        backgroundColor: 'white',
        border: '2px solid black',
        borderRadius: '10px',
        cursor: 'pointer',
        display: 'flex',
        height: '120px',
        justifyContent: 'center',
        marginTop: '8px',
        width: 'calc(100vw - 320px)',
    },
    dragOver: {
        backgroundColor: 'lightgray',
    },
    fileInput: {
        display: 'none',
    },
    normalText: {
        color: 'black',
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: '400',
    },
    rowText: {
        color: 'black',
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: '400',
        textAlign: 'left',
    },
    rowTextTitle: {
        color: 'black',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: '600',
        textDecoration: 'underline',
        textAlign: 'left',
    },
    seatCount: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '5px',
    },
    subTitle: {
        color: 'black',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: '600',
    },
    title: {
        color: 'black',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: '600',
    },
    uploadIcon: {
        fontSize: '40px',
        marginRight: '10px',
    },
    userInviteStatus: {
        backgroundColor: 'white',
        border: '2px solid black',
        borderRadius: '10px',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '8px',
        marginBottom: '10px',
        padding: '10px',
        width: 'calc(100vw - 340px)',
    },
}));

function PremiumInvitePage() {
    const classes = useStyles();
    const [isDragOver, setIsDragOver] = useState(false);
    const [data, setData] = useState([]);
    const [premiumInviteInfo, setPremiumInviteInfo] = useState([]);
    const fileInputRef = useRef(null);
    const stateInfo = JSON.parse(localStorage.getItem('stateInfo'));

    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDragOver(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragOver(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragOver(false);

        const file = e.dataTransfer.files[0];
        handleFileUpload(file);
    };

    const handleFileInput = (e) => {
        const file = e.target.files[0];
        handleFileUpload(file);
    };

    const handleFileUpload = async (file) => {
        if (file && file.type === 'text/csv') {
            console.log('File Uploaded:', file);

            const data = new FormData();
            data.append('file', file);

            try {
                const isAuthenticated = JSON.parse(localStorage.getItem('userInfo'));
                const authToken = isAuthenticated ? isAuthenticated.token : '';

                const response = await fetch('https://stormy-forest-22913.herokuapp.com/api/organization-premium-invite-csv/', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Token ${authToken}`
                    },
                    body: data
                });

                if (!response.ok) {
                    fileInputRef.current.value = '';
                    throw new Error('Failed to upload CSV File');
                }

                console.log('CSV file Uploaded Successfully');
                fileInputRef.current.value = '';
                fetchPremiumInfo();
            } catch (error) {
                console.error(error);
            }
        } else {
            console.log('Invalid File Format. Only CSV Files Are Allowed.');
            fileInputRef.current.value = '';
        }
    };

    const handleClick = () => {
        fileInputRef.current.click();
    };

    const fetchPremiumInfo = async () => {
        const isAuthenticated = JSON.parse(localStorage.getItem('userInfo'));
        const authToken = isAuthenticated ? isAuthenticated.token : '';

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`,
            },
        };

        fetch('https://stormy-forest-22913.herokuapp.com/api/organization-premium-invite/', requestOptions)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to Retrieve Invites');
                }
            })
            .then(data => {
                console.log(data);
                setPremiumInviteInfo(data);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const fetchDashboardInfo = async () => {
        try {
            const isAuthenticated = JSON.parse(localStorage.getItem('userInfo'));
            const authToken = isAuthenticated ? isAuthenticated.token : '';

            const response = await fetch(`https://stormy-forest-22913.herokuapp.com/api/organization-dashboard-info/`, {
                headers: {
                    'Authorization': `Token ${authToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to Fetch Education Info');
            }
            const jsonData = await response.json();
            setData(jsonData || []);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            await fetchPremiumInfo();
        };
        fetchDashboardInfo();

        fetchData();
    }, []);


    useEffect(() => {
    }, [premiumInviteInfo]);

    return (
        <div style={{ marginTop: 70 }}>
            <Typography className={classes.subTitle}>
                Upload CSV
            </Typography>
            <Typography className={classes.normalText}>
                Upload a CSV file of User Emails to be added to Haven Premium
            </Typography>
            <div
                className={`${classes.dragDropField} ${isDragOver ? classes.dragOver : ''}`}
                onDragEnter={handleDragEnter}
                onDragOver={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onClick={handleClick}
            >
                <input
                    type="file"
                    accept=".csv"
                    ref={fileInputRef}
                    onChange={handleFileInput}
                    className={classes.fileInput}
                />
                <CloudUploadOutlined className={classes.uploadIcon} />
                <div className={classes.normalText}>Drag &amp; Drop or Click to Upload Files</div>
            </div>
            <div className={classes.seatCount}>
                <Typography className={classes.normalText}>
                    {premiumInviteInfo.length}/{stateInfo.org_seats} seats used
                </Typography>
            </div>
            <Typography className={classes.subTitle} style={{ marginTop: 10 }}>
                User Invitation Status
            </Typography>
            <Typography className={classes.normalText}>
                {(data.acceptance_rate * 100).toFixed(2)}% Acceptance Rate
            </Typography>
            <div className={classes.userInviteStatus}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                    <div style={{ flexBasis: '70%', marginRight: '10px' }}>
                        <Typography className={classes.rowTextTitle}>Email</Typography>
                    </div>
                    <div style={{ flexBasis: '10%', marginRight: '10px' }}>
                        <Typography className={classes.rowTextTitle}>Date</Typography>
                    </div>
                    <div style={{ flexBasis: '10%' }}>
                        <Typography className={classes.rowTextTitle}>Status</Typography>
                    </div>
                </div>
                    {premiumInviteInfo.map((row, index) => (
                        <React.Fragment key={index}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ flexBasis: '70%', marginRight: '10px' }}>
                                    <Typography className={classes.rowText}>{row.email}</Typography>
                                </div>
                                <div style={{ flexBasis: '10%', marginRight: '10px' }}>
                                    <Typography className={classes.rowText}>{row.sent_date}</Typography>
                                </div>
                                <div style={{ flexBasis: '10%' }}>
                                    <Typography
                                        className={`${classes.rowText} ${row.status === 'Accepted' ? classes.acceptedText : ''}`}
                                    >
                                        {row.status.toUpperCase()}
                                    </Typography>
                                </div>
                            </div>
                            {index !== premiumInviteInfo.length - 1 && <Divider className={classes.divider} />}
                        </React.Fragment>
                    ))}
            </div>
        </div>
    );

}

export default PremiumInvitePage;