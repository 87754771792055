import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ExitToApp } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  navbar: {
    backgroundColor: '#fff',
    borderBottom: '1px solid #ccc',
    overflowX: 'hidden',

  },
  logo: {
    marginRight: theme.spacing(1),
  },
  title: {
    color: 'black',
    fontWeight: '500',
    fontFamily: 'Poppins',
    fontSize: '24px',
  },
  logoutButton: {
    marginLeft: 'auto',
  },
}));

const Navbar = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('userInfo');
    navigate('/login');
  };

  return (
    <AppBar position="static" className={classes.navbar}>
      <Toolbar>
        <img
          src="https://webappvisuals.s3.ca-central-1.amazonaws.com/Official+Haven+Logo+Green.png"
          alt="Logo"
          className={classes.logo}
          width="110"
          height="50"
        />
        <Typography variant="h6" className={classes.title}>
          Safety Portal
        </Typography>
        <Button className={classes.logoutButton}
          onClick={handleLogout}
          startIcon={<ExitToApp />}>
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
