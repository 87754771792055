import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    addButton: {
        backgroundColor: '#2FD3B5',
        borderRadius: 10,
        color: 'white',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: '600',
        '&:hover': {
            backgroundColor: '#2FD3B5',
        },
    },
    normalText: {
        color: 'black',
        fontWeight: '400',
        fontFamily: 'Poppins',
        fontSize: '12px',
    },
    redButton: {
        color: '#F53361',
    },
    subTitle: {
        color: 'black',
        fontWeight: '600',
        fontFamily: 'Poppins',
        fontSize: '16px',
    },
    title: {
        color: 'black',
        fontWeight: '600',
        fontFamily: 'Poppins',
        fontSize: '24px',
    },
}));

const EducationPage = () => {
    const classes = useStyles();
    const [titles, setTitles] = useState([]);

    const fetchEducationInfo = async () => {
        try {
            const isAuthenticated = JSON.parse(localStorage.getItem('userInfo'));
            const authToken = isAuthenticated ? isAuthenticated.token : '';

            const response = await fetch(`https://stormy-forest-22913.herokuapp.com/api/organization-education/`, {
                headers: {
                    'Authorization': `Token ${authToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to Fetch Education Info');
            }

            const data = await response.json();
            setTitles(data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleAddTitle = async () => {
        const isAuthenticated = JSON.parse(localStorage.getItem('userInfo'));
        const authToken = isAuthenticated ? isAuthenticated.token : '';

        const newTitle = {
            org_state: `${isAuthenticated.org_state}`,
            verbose_title: 'New Title'
        };

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`,
            },
            body: JSON.stringify(newTitle)
        };

        fetch('https://stormy-forest-22913.herokuapp.com/api/organization-education-titles/', requestOptions)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to Add Title');
                }
            })
            .then(data => {
                fetchEducationInfo();
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const handleRemoveTitle = async (titleId) => {
        const isAuthenticated = JSON.parse(localStorage.getItem('userInfo'));
        const authToken = isAuthenticated ? isAuthenticated.token : '';

        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`,
            },
        };

        try {
            const response = await fetch(`https://stormy-forest-22913.herokuapp.com/api/organization-education-titles/${titleId}/`, requestOptions);

            if (response.ok) {
                fetchEducationInfo();
            } else {
                throw new Error('Failed to Remove Title');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchEducationInfo();
        const intervalId = setInterval(fetchEducationInfo, 5 * 60 * 1000);
        return () => clearInterval(intervalId);
    }, []);

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 70, width: 'calc(100vw - 320px)' }}>
                <Typography className={classes.title}>
                    Topics
                </Typography>
                <Button style={{ padding: '5px 20px' }} startIcon={<Add />} className={classes.addButton} onClick={handleAddTitle}>
                    Add Title
                </Button>
            </div>
            <Typography className={classes.normalText} style={{ marginBottom: 10 }}>
                Add additional Education Centre topics or click to edit your existing Education Centre topics.
            </Typography>
            {titles.map((title) => (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 'calc(100vw - 340px)', padding: '10px', backgroundColor: '#FFF', border: '1px solid black', borderRadius: 10, marginBottom: 5 }} key={title.title_id}>
                    <Link className={classes.subTitle}
                        to={{
                            pathname: `/education/${title.title_id}`,
                            state: {
                                title: title,
                            },
                        }}>
                        {title.verbose_title}
                    </Link>
                    <Button className={classes.redButton} onClick={() => handleRemoveTitle(title.title_id)} startIcon={<Delete />}>
                        Delete
                    </Button>
                </div>
            ))}
        </div>
    );
};

export default EducationPage;
