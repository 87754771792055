import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Button, Typography, TextField, makeStyles } from '@material-ui/core';
import { Add, Edit, Delete } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    addButton: {
        backgroundColor: '#2FD3B5',
        borderRadius: 10,
        color: 'white',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: '600',
        '&:hover': {
            backgroundColor: '#2FD3B5',
        },
    },
    normalText: {
        color: 'black',
        fontWeight: '400',
        fontFamily: 'Poppins',
        fontSize: '12px',
    },
    redButton: {
        color: '#F53361',
    },
    subTitle: {
        color: 'black',
        fontWeight: '600',
        fontFamily: 'Poppins',
        fontSize: '16px',
    },
    title: {
        color: 'black',
        fontWeight: '600',
        fontFamily: 'Poppins',
        fontSize: '24px',
    },
}));

const EducationDetailPage = () => {
    const { titleId } = useParams();
    const [title, setTitle] = useState(null);
    const [titleInput, setTitleInput] = useState('');
    const [isEditingTitle, setEditingTitle] = useState(false);
    const classes = useStyles();

    const fetchEducationInfo = async () => {
        try {
            const isAuthenticated = JSON.parse(localStorage.getItem('userInfo'));
            const authToken = isAuthenticated ? isAuthenticated.token : '';

            const response = await fetch(`https://stormy-forest-22913.herokuapp.com/api/organization-education/`, {
                headers: {
                    'Authorization': `Token ${authToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to Fetch Education Info');
            }

            const data = await response.json();
            const titleData = data.find(item => item.title_id === titleId);

            if (titleData) {
                setTitle(titleData);
            } else {
                setTitle({ title_id: titleId, verbose_title: "No Data For This Title", articles: [] });
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchEducationInfo();
    }, []);

    useEffect(() => {
        if (title) {
            setTitleInput(title.verbose_title);
        }
    }, [title]);

    const handleUpdateTitle = async () => {
        try {
            const isAuthenticated = JSON.parse(localStorage.getItem('userInfo'));
            const authToken = isAuthenticated ? isAuthenticated.token : '';

            const response = await fetch(`https://stormy-forest-22913.herokuapp.com/api/organization-education-titles/${titleId}/`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${authToken}`,
                },
                body: JSON.stringify({ verbose_title: titleInput }),
            });

            if (response.ok) {
                const updatedTitle = await response.json();
                setTitle(updatedTitle);
                setTitleInput('');
                fetchEducationInfo();
                setEditingTitle(false);
            } else {
                throw new Error('Failed to Update Title');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleAddArticle = async () => {
        try {
            const isAuthenticated = JSON.parse(localStorage.getItem('userInfo'));
            const authToken = isAuthenticated ? isAuthenticated.token : '';

            const response = await fetch(`https://stormy-forest-22913.herokuapp.com/api/organization-education-articles/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${authToken}`,
                },
                body: JSON.stringify({ title_id: titleId, article_title: "New Article Title, Click to Edit", article_content: "Put Your New Article Content Here. Write AWAY!" }),
            });

            if (response.ok) {
                const newArticle = await response.json();
                setTitle(prevState => ({ ...prevState, articles: [...prevState.articles, newArticle] }));
            } else {
                throw new Error('Failed to Add Article');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleRemoveArticle = async (articleId) => {
        try {
            const isAuthenticated = JSON.parse(localStorage.getItem('userInfo'));
            const authToken = isAuthenticated ? isAuthenticated.token : '';

            const response = await fetch(`https://stormy-forest-22913.herokuapp.com/api/organization-education-articles/${articleId}/`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${authToken}`,
                },
            });

            if (response.ok) {
                setTitle(prevState => ({ ...prevState, articles: prevState.articles.filter(article => article.article_id !== articleId) }));
            } else {
                throw new Error('Failed to Remove Article');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleTitleInputChange = (event) => {
        setTitleInput(event.target.value);
    };

    const handleTitleInputBlur = () => {
        if (titleInput.trim() !== '') {
            handleUpdateTitle();
        }
        setEditingTitle(false);
    };

    return (
        <div style={{ marginTop: 70 }}>
            {title && (
                <>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: 'calc(100vw - 320px)' }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {isEditingTitle ? (
                                <TextField
                                    value={titleInput}
                                    onChange={handleTitleInputChange}
                                    onBlur={handleTitleInputBlur}
                                    autoFocus
                                    InputProps={{
                                        className: classes.title,
                                    }}
                                />
                            ) : (
                                <Typography
                                    className={classes.title}
                                    onClick={() => setEditingTitle(true)}
                                >
                                    {title.verbose_title}
                                </Typography>
                            )}
                            <Button style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 10, borderRadius: 100 }} startIcon={<Edit />} onClick={() => setEditingTitle(true)}> Edit Title </Button>

                        </div>
                        <Button
                            style={{ padding: '5px 20px' }}
                            startIcon={<Add />}
                            className={classes.addButton}
                            onClick={handleAddArticle}
                        >
                            Add Article
                        </Button>
                    </div>
                    <Typography className={classes.normalText} style={{ marginBottom: 10 }}>
                        Add additional Education Centre articles or click to edit your existing Education Centre articles.            
                    </Typography>
                    {title.articles && title.articles.map((article) => (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 'calc(100vw - 340px)', padding: '10px', marginBottom: 5,  backgroundColor: '#FFF', border: '1px solid black', borderRadius: 10 }} key={article.article_id}>
                            <Link className={classes.subTitle}
                                to={{
                                    pathname: `/education/${titleId}/${article.article_id}`,
                                    state: { article: article, title: title }
                                }}
                            >
                                {article.article_title}
                            </Link>
                            <Button className={classes.redButton} onClick={() => handleRemoveArticle(article.article_id)} startIcon={<Delete/>}>
                                Delete
                            </Button>
                        </div>
                    ))}
                </>
            )}
        </div>
    );
};

export default EducationDetailPage;
