import React, { useState, useEffect } from 'react';
import { Divider, Typography, TextField, Button, makeStyles } from '@material-ui/core';
import { Send } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    acceptedText: {
        color: '#2FD3B5',
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: '600',
        textAlign: 'left',
    },
    characterCount: {
        color: 'black',
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: '400',
        marginTop: '5px',
    },
    divider: {
        margin: '6px 0',
    },
    normalText: {
        color: 'black',
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: '400',
    },
    rowText: {
        color: 'black',
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: '400',
        textAlign: 'left',
    },
    rowTextTitle: {
        color: 'black',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: '600',
        textAlign: 'left',
        textDecoration: 'underline',
    },
    seatCount: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '5px',
    },
    sendButton: {
        backgroundColor: '#2FD3B5',
        borderRadius: 10,
        color: 'white',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: '600',
        padding: '5px 20px',
        '&:hover': {
            backgroundColor: '#2FD3B5',
        },
    },
    subTitle: {
        color: 'black',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: '600',
    },
    textField: {
        flex: '1',
        marginRight: '10px',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'black',
            },
            '&:hover fieldset': {
                borderColor: 'black',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'black',
            },
        },
    },
    textFieldContainer: {
        alignItems: 'center',
        backgroundColor: 'white',
        border: '2px solid black',
        borderRadius: '10px',
        display: 'flex',
        height: '120px',
        justifyContent: 'center',
        marginTop: '8px',
        marginBottom: '10px',
        padding: '10px',
        width: 'calc(100vw - 340px)',
    },
    title: {
        color: 'black',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: '600',
    },
    userInviteStatus: {
        backgroundColor: 'white',
        border: '2px solid black',
        borderRadius: '10px',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '8px',
        marginBottom: '10px',
        padding: '10px',
        width: 'calc(100vw - 340px)',
    },
}));

function NotificationPage() {
    const classes = useStyles();
    const [notificationInfo, setNotificationInfo] = useState([]);
    const [characterCount, setCharacterCount] = useState(0);
    const [header, setHeader] = useState("");
    const [message, setMessage] = useState("");

    const handleTextChange = (event) => {
        const count = event.target.value.length;
        setCharacterCount(count);
    };

    const fetchNotificationInfo = async () => {
        const isAuthenticated = JSON.parse(localStorage.getItem('userInfo'));
        const authToken = isAuthenticated ? isAuthenticated.token : '';

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`,
            },
        };

        fetch('https://stormy-forest-22913.herokuapp.com/api/organization-push-notification/', requestOptions)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to Retrieve Invites');
                }
            })
            .then(data => {
                setNotificationInfo(data);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const sendNotification = async () => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${JSON.parse(localStorage.getItem('userInfo')).token}`
            },
            body: JSON.stringify({
                header: header,
                message: message
            })
        };

        try {
            const response = await fetch('https://stormy-forest-22913.herokuapp.com/api/organization-push-notification/', requestOptions);
            if (!response.ok) {
                throw new Error('HTTP status ' + response.status);
            }
            await fetchNotificationInfo();
            setMessage('');
            setHeader('');
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchNotificationInfo();
        };

        fetchData();
    }, []);

    return (
        <div style={{ marginTop: 70 }}>
            <Typography className={classes.subTitle}>
                Send Push Notification
            </Typography>
            <Typography className={classes.normalText}>
                Write a Short Message and We’ll Send It to All Users.
            </Typography>
            <div className={classes.textFieldContainer}>
                <TextField
                    variant="outlined"
                    placeholder="Enter Header Here..."
                    className={classes.textField}
                    onChange={(e) => setHeader(e.target.value)}

                    inputProps={{ maxLength: 20 }}
                />
                <TextField
                    variant="outlined"
                    placeholder="Enter Message Here..."
                    className={classes.textField}
                    onChange={(e) => {
                        setMessage(e.target.value);
                        handleTextChange(e);
                    }}
                    inputProps={{ maxLength: 100 }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.sendButton}
                    startIcon={<Send />}
                    onClick={sendNotification}
                >
                    Send
                </Button>
            </div>
            <div className={classes.seatCount}>
                <Typography
                    className={classes.normalText}
                    style={{ marginLeft: 'auto' }}
                >
                    {100 - characterCount} characters remaining
                </Typography>
            </div>
            <Typography className={classes.subTitle} style={{ marginTop: 10 }}>
                Push Notification History
            </Typography>
            <div className={classes.userInviteStatus}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
                    <div style={{ flexBasis: '70%', marginRight: '10px' }}>
                        <Typography className={classes.rowTextTitle}>Message</Typography>
                    </div>
                    <div style={{ flexBasis: '17%', marginRight: '10px' }}>
                        <Typography className={classes.rowTextTitle}>
                            Date Sent
                        </Typography>
                    </div>
                </div>
                {notificationInfo.map((row, index) => (
                    <React.Fragment key={index}>
                        {notificationInfo[notificationInfo.length - index - 1] && (
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ flexBasis: '70%', marginRight: '10px' }}>
                                    <Typography className={classes.rowText}>
                                        {notificationInfo[notificationInfo.length - index - 1].header} - {notificationInfo[notificationInfo.length - index - 1].message}
                                    </Typography>
                                </div>
                                <div style={{ flexBasis: '17%', marginRight: '10px' }}>
                                    <Typography className={classes.rowText}>
                                        {formatDate(notificationInfo[notificationInfo.length - index - 1].date_sent)}
                                    </Typography>
                                </div>
                            </div>
                        )}
                        {index !== notificationInfo.length - 1 && <Divider className={classes.divider} />}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}

export default NotificationPage;