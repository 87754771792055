import React, { useEffect, useState } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { GoogleMap, HeatmapLayer, LoadScript } from '@react-google-maps/api';

const useStyles = makeStyles((theme) => ({
    incidentContainer: {
        height: "70vh",
        width: "30vw",
        marginLeft: 10,
        marginRight: 10,
        overflow: 'auto',
    },
    incidentItem: {
        backgroundColor: "#FFF",
        border: "1px solid #000",
        borderRadius: 10,
        marginBottom: "5px",
        padding: "10px",
    },
    mapContainer: {
        height: "70vh",
        width: "45vw",
    },
    normalText: {
        color: 'black',
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: '400',
    },
    textFieldContainer: {
        alignItems: 'center',
        backgroundColor: 'white',
        border: '2px solid black',
        borderRadius: '10px',
        display: 'flex',
        height: '120px',
        justifyContent: 'center',
        marginTop: '8px',
        marginBottom: '10px',
        padding: '10px',
        width: 'calc(100vw - 340px)',
    },
    title: {
        color: 'black',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: '600',
    },
}));

const mapStyles = {
    height: "70vh",
    width: "45vw",
};

const defaultCenter = {
    lat: 37.782,
    lng: -122.447,
};

const libraries = ["visualization"];

function MapPage() {
    const classes = useStyles();
    const [map, setMap] = useState(null);
    const [heatmapData, setHeatmapData] = useState([]);
    const [emergencyInfo, setEmergencyInfo] = useState([]);
    const [isMapLoaded, setIsMapLoaded] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    const fetchMapInfo = async () => {
        const isAuthenticated = JSON.parse(localStorage.getItem('userInfo'));
        const authToken = isAuthenticated ? isAuthenticated.token : '';

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${authToken}`,
            },
        };

        fetch('https://stormy-forest-22913.herokuapp.com/api/organization-map-info/', requestOptions)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to Retrieve Map Info');
                }
            }).then(data => {
                const parsedIncidents = data.map((item) => {
                    const dateTime = new Date(item.time).toLocaleString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        hour12: true
                    });

                    let location = null;
                    let preciseData = null;
                    if (item.action.substring(0, 13) === 'Currently at ') {
                        const latitude = parseFloat(item.action.substring(13, 23));
                        const longitude = parseFloat(item.action.substring(24));
                        location = {
                            lat: latitude,
                            lng: longitude,
                        };
                    }
                    if (item.precise_data) {
                        preciseData = item.precise_data.installationDescription + ", " + item.precise_data.addressLine2;
                    }
                    return {
                        action: item.action,
                        location: location,
                        type: 'Emergency',
                        preciseData: preciseData,
                        date: dateTime
                    };
                });

                setEmergencyInfo(parsedIncidents);
                setIsDataLoaded(true);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    useEffect(() => {
        const fetchData = async () => {
            await fetchMapInfo();
        };

        fetchData();
    }, []);

    const onLoad = React.useCallback(function callback(map) {
        setIsMapLoaded(true);
        setMap(map);
    }, [])

    useEffect(() => {
        if (isMapLoaded && isDataLoaded) {
            const bounds = new window.google.maps.LatLngBounds();

            const data = emergencyInfo
                .filter(incident => incident.location !== null)
                .map(incident => {
                    const latitude = parseFloat(incident.location.lat);
                    const longitude = parseFloat(incident.location.lng);
                    const location = new window.google.maps.LatLng(latitude, longitude);
                    bounds.extend(location);
                    return {
                        location: location,
                        weight: 5
                    };
                });

            if (data.length > 0) {
                map.fitBounds(bounds);
            }

            setHeatmapData(data);
        }
    }, [isMapLoaded, isDataLoaded, emergencyInfo, map])

    return (
        <div style={{ marginTop: 70 }}>
            <Typography className={classes.title}>
                Heat Map
            </Typography>
            <Typography className={classes.normalText}>
                Visually View Where Incidents and Emergency Modes are Occurring to Better Allocate Resources and Personnel.
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                <div id="map" className={classes.mapContainer}>
                    <LoadScript
                        googleMapsApiKey="AIzaSyDZcBUFs9XuP0qRXEb0-0jXGm5vEwV6Ss8"
                        libraries={libraries}
                    >
                        <GoogleMap
                            mapContainerStyle={mapStyles}
                            zoom={13}
                            center={defaultCenter}
                            onLoad={onLoad}
                        >
                            <HeatmapLayer data={heatmapData} />
                        </GoogleMap>
                    </LoadScript>
                </div>
                <div className={classes.incidentContainer}>
                    {emergencyInfo.slice().reverse().map((incident, index) => (
                        <div className={classes.incidentItem} key={index}>
                            <Typography className={classes.normalText}>
                                <strong>Type:</strong> {incident.action}
                            </Typography>
                            {incident.location && (
                                <Typography className={classes.normalText}>
                                    <strong>Location:</strong> {incident.location.lat}, {incident.location.lng}
                                </Typography>
                            )}
                            {incident.preciseData && (
                                <Typography className={classes.normalText}>
                                    <strong>Precise Data:</strong> {incident.preciseData}
                                </Typography>
                            )}
                            <Typography className={classes.normalText}>
                                <strong>Date:</strong> {incident.date}
                            </Typography>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default MapPage;
