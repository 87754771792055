import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, makeStyles, TextField, Typography } from '@material-ui/core';
import { Cancel, Edit, Save } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    addButton: {
        backgroundColor: '#2FD3B5',
        borderRadius: 10,
        color: 'white',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: '600',
        '&:hover': {
            backgroundColor: '#2FD3B5',
        },
    },
    checkbox: {
        appearance: 'none',
        backgroundColor: 'white',
        border: '2px solid black',
        borderRadius: '50%',
        height: '19px',
        '&:checked': {
            backgroundColor: 'black',
        },
        '&:focus': {
            outline: 'none',
        },
        width: '20px',
    },
    inputContainer: {
        flexBasis: '50%',
        marginRight: '10px',
    },
    inputField: {
        border: '1px solid #ccc',
        borderRadius: '4px',
        padding: '6px',
        width: '95%',
    },
    linkContainer: {
        alignItems: 'center',
        display: 'flex',
        marginBottom: '5px',
    },
    normalText: {
        color: 'black',
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontWeight: '400',
    },
    rowTextTitle: {
        color: 'black',
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontWeight: '600',
    },
    title: {
        color: 'black',
        fontFamily: 'Poppins',
        fontSize: '24px',
        fontWeight: '600',
    },
}));

const ArticleDetailPage = () => {
    const classes = useStyles();
    const { titleId, articleId } = useParams();
    const [article, setArticle] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);

    const fetchEducationInfo = async () => {
        try {
            const isAuthenticated = JSON.parse(localStorage.getItem('userInfo'));
            const authToken = isAuthenticated ? isAuthenticated.token : '';

            const response = await fetch(`https://stormy-forest-22913.herokuapp.com/api/organization-education/`, {
                headers: {
                    'Authorization': `Token ${authToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to Fetch Education Info');
            }

            const data = await response.json();
            const titleData = data.find(item => item.title_id === titleId);
            let articleData;
            data.some(titleData => {
                const foundArticle = titleData.articles.find(article => article.article_id === articleId);
                if (foundArticle) {
                    articleData = foundArticle;
                    setArticle(articleData);
                    return true;
                }
                return false;
            });

            if (titleData && articleData) {
                setArticle(articleData);
            } else {
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchEducationInfo();
    }, []);

    const handleToggleLinkView = async (linkId) => {
        try {
            const isAuthenticated = JSON.parse(localStorage.getItem('userInfo'));
            const authToken = isAuthenticated ? isAuthenticated.token : '';

            const linkToUpdate = article.links.find((link) => link.link_id === linkId);
            if (!linkToUpdate) return;

            const updatedLink = { ...linkToUpdate, enabled: linkToUpdate.enabled };

            const response = await fetch(`https://stormy-forest-22913.herokuapp.com/api/organization-education-links/${linkId}/`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${authToken}`,
                },
                body: JSON.stringify({ link_title: updatedLink.link_title }),
            });

            if (response.ok) {
                const updatedLinks = article.links.map((link) =>
                    link.link_id === linkId ? { ...updatedLink } : link
                );
                setArticle((prevArticle) => ({ ...prevArticle, links: updatedLinks }));

            } else {
                throw new Error('Failed to Update Link Title');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleUpdateLinkUrl = async (linkId, updatedLinkUrl) => {
        try {
            const isAuthenticated = JSON.parse(localStorage.getItem('userInfo'));
            const authToken = isAuthenticated ? isAuthenticated.token : '';
    
            const response = await fetch(`https://stormy-forest-22913.herokuapp.com/api/organization-education-links/${linkId}/`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${authToken}`,
                },
                body: JSON.stringify({ link: updatedLinkUrl }),
            });
    
            if (response.ok) {
                const updatedLinks = article.links.map((link) =>
                    link.link_id === linkId ? { ...link, link: updatedLinkUrl } : link
                );
                setArticle((prevArticle) => ({ ...prevArticle, links: updatedLinks }));
    
            } else {
                throw new Error('Failed to Update Link URL');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleUpdateLinkTitle = async (linkId, updatedLinkTitle) => {
        try {
            const isAuthenticated = JSON.parse(localStorage.getItem('userInfo'));
            const authToken = isAuthenticated ? isAuthenticated.token : '';
    
            const response = await fetch(`https://stormy-forest-22913.herokuapp.com/api/organization-education-links/${linkId}/`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${authToken}`,
                },
                body: JSON.stringify({ link_title: updatedLinkTitle }),
            });
    
            if (response.ok) {
                const updatedLinks = article.links.map((link) =>
                    link.link_id === linkId ? { ...link, link_title: updatedLinkTitle } : link
                );
                setArticle((prevArticle) => ({ ...prevArticle, links: updatedLinks }));
    
            } else {
                throw new Error('Failed to Update Link Title');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleToggleLinkEnabled = async (linkId) => {
        try {
            const isAuthenticated = JSON.parse(localStorage.getItem('userInfo'));
            const authToken = isAuthenticated ? isAuthenticated.token : '';

            const linkToUpdate = article.links.find((link) => link.link_id === linkId);
            if (!linkToUpdate) return;

            const updatedLink = { ...linkToUpdate, enabled: !linkToUpdate.enabled };

            const response = await fetch(`https://stormy-forest-22913.herokuapp.com/api/organization-education-links/${linkId}/`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${authToken}`,
                },
                body: JSON.stringify({ enabled: updatedLink.enabled }),
            });

            if (response.ok) {
                const updatedLinks = article.links.map((link) =>
                    link.link_id === linkId ? { ...updatedLink } : link
                );
                setArticle((prevArticle) => ({ ...prevArticle, links: updatedLinks }));
            } else {
                throw new Error('Failed to Toggle Link Enabled');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleUpdateArticleTitle = async (updatedTitle) => {
        try {
            const isAuthenticated = JSON.parse(localStorage.getItem('userInfo'));
            const authToken = isAuthenticated ? isAuthenticated.token : '';

            const response = await fetch(`https://stormy-forest-22913.herokuapp.com/api/organization-education-articles/${articleId}/`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${authToken}`,
                },
                body: JSON.stringify({ article_title: updatedTitle }),
            });

            if (response.ok) {
                const updatedArticle = await response.json();
                setArticle(prevArticle => ({
                    ...prevArticle,
                    article_title: updatedArticle.article_title,
                }));
            } else {
                throw new Error('Failed to Update Article Title');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleUpdateArticleContent = async (updatedContent) => {
        try {
            const isAuthenticated = JSON.parse(localStorage.getItem('userInfo'));
            const authToken = isAuthenticated ? isAuthenticated.token : '';

            const response = await fetch(`https://stormy-forest-22913.herokuapp.com/api/organization-education-articles/${articleId}/`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${authToken}`,
                },
                body: JSON.stringify({ article_content: updatedContent }),
            });

            if (response.ok) {
                const updatedArticle = await response.json();
                setArticle(prevArticle => ({
                    ...prevArticle,
                    article_content: updatedArticle.article_content,
                }));
            } else {
                throw new Error('Failed to Update Article Content');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleEditClick = () => {
        if (isEditMode) {
            setIsEditMode(false);
        } else {
            setIsEditMode(true);
        }
    };

    return (
        <div>
            {article && (
                <>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: 70 }}>
                        <Typography className={classes.title} style={{ marginRight: '10px' }}>
                            {isEditMode ? (
                                <TextField
                                    type="text"
                                    value={article.article_title}
                                    onChange={(e) =>
                                        setArticle((prevArticle) => ({ ...prevArticle, article_title: e.target.value }))
                                    }
                                    InputProps={{
                                        className: classes.title,
                                    }}
                                    onBlur={() => {
                                        handleUpdateArticleTitle(article.article_title);
                                        setIsEditMode(false);
                                    }}
                                />
                            ) : (
                                article.article_title
                            )}
                        </Typography>
                        <Button style={{ marginLeft: 10, borderRadius: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }} startIcon={isEditMode ? <Cancel /> : <Edit />} onClick={handleEditClick}>
                            {isEditMode ? 'Cancel Changes' : 'Edit Title'}
                        </Button>
                    </div>
                    <textarea
                        rows={10}
                        className={classes.normalText}
                        style={{ marginTop: 5, width: 'calc(100vw - 320px)' }}
                        value={article.article_content}
                        onChange={(e) =>
                            setArticle((prevArticle) => ({ ...prevArticle, article_content: e.target.value }))
                        }
                        onBlur={() => {
                            handleUpdateArticleContent(article.article_content);
                            setIsEditMode(false);
                        }}
                    />
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            style={{ padding: '5px 20px' }}
                            startIcon={<Save />}
                            className={classes.addButton}
                        >
                            Save
                        </Button>
                    </div>
                    <Typography className={classes.title} style={{ marginTop: '10px' }}>
                        Links
                    </Typography>
                    <div className={classes.linkContainer}>
                        <Typography className={classes.normalText} style={{ width: 40, textDecoration: 'underline' }}>
                            Show
                        </Typography>
                        <div className={classes.inputContainer}>
                            <Typography className={classes.normalText} style={{ textDecoration: 'underline' }}>
                                Link Title
                            </Typography>
                        </div>
                        <div className={classes.inputContainer} style={{ textDecoration: 'underline' }}>
                            <Typography className={classes.normalText}>
                                Link URL
                            </Typography>
                        </div>
                    </div>
                    <div>
                        {article.links.map((link) => (
                            <div key={link.link_id} className={classes.linkContainer}>
                                <input
                                    className={classes.checkbox}
                                    type="checkbox"
                                    checked={link.enabled}
                                    onChange={() => handleToggleLinkEnabled(link.link_id)}
                                />
                                <div className={classes.inputContainer} style={{ marginLeft: 10 }}>
                                <input
                                    className={classes.inputField}
                                    type="text"
                                    value={link.link_title}
                                    onChange={(e) => {
                                        const updatedLinks = article.links.map((prevLink) =>
                                            prevLink.link_id === link.link_id ? { ...prevLink, link_title: e.target.value } : prevLink // And here
                                        );
                                        setArticle((prevArticle) => ({ ...prevArticle, links: updatedLinks }));
                                    }}
                                    onBlur={() => handleUpdateLinkTitle(link.link_id, link.link_title)} // And here
                                />
                            </div>
                            <div className={classes.inputContainer}>
                                <input
                                    className={classes.inputField}
                                    type="text"
                                    value={link.link}
                                    onChange={(e) => {
                                        const updatedLinks = article.links.map((prevLink) =>
                                            prevLink.link_id === link.link_id ? { ...prevLink, link: e.target.value } : prevLink
                                        );
                                        setArticle((prevArticle) => ({ ...prevArticle, links: updatedLinks }));
                                    }}
                                    onBlur={() => handleUpdateLinkUrl(link.link_id, link.link)}
                                />
                            </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default ArticleDetailPage;
